import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import 'rxjs/Rx';

import {ApiService} from './api.service';

@Injectable()
export class PlanService {
  constructor(private http: HttpClient, private apiService: ApiService) {
  }

  /**
   * 요금제
   */
  public planList() {
    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/service/client/plan');
  }
}
