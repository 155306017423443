import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpRequest} from '@angular/common/http';

import {ApiService} from '../common/api.service';
import 'rxjs/add/operator/map';
import {CreateNotice, ModifyNotice, Notice, NoticeTemplate} from "../../_models/receipt/notice.model";

@Injectable()
export class NoticeService {
  constructor(private http: HttpClient, private apiService: ApiService) {
  }

  /******************************************************************
   * 청구 조회
   *******************************************************************/

  /**
   * 청구 목록
   */
  getNoticeList(searchParam) {
    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/notice?' + searchParam);
  }

  /**
   * 마지막 청구년월
   */
  getMaxMonth() {
    const params = new HttpParams();

    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/notice/max-month', {
      params: params
    });
  }

  /**
   * 청구 상세 조회
   */
  getNoticeInfo(id: string) {
    const params = new HttpParams();

    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/notice/' + id, {
      params: params
    });
  }

  /**
   * 청구 수정
   */
  modifyNotice(modNotice: ModifyNotice) {
    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/notice/' + modNotice.consumerId + '/modify', modNotice);
  }

  /**
   * 청구 취소
   */
  removeNotice(param) {
    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/notice/remove', param).map(function (response) {
      return response;
    });
  }

  /**
   * 청구 일괄 수정
   */
  bulkNotice(param) {
    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/notice/modify/bulk', param).map(function (response) {
      return response;
    });
  }


  /******************************************************************
   * 청구 등록
   *******************************************************************/

  /**
   * 청구대상 목록 ( 임시 청구 정보 )
   */
  getNoticeTemporaryList(searchParam) {
    const params = new HttpParams();

    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/notice/draft?' + searchParam, {
      params: params
    });
  }

  /**
   * 개별청구등록
   */
  createNoticeInfo(notice) {
    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/notice', notice).map(function (response) {
      return response;
    });
  }

  /**
   * 청구 엑셀파일 대량등록
   */
  createNoticeWithExcel(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/notice/upload', formData).map(function (response) {
      return response;
    });
  }

  /**
   * 청구 임시 -> 청구 등록
   */
  submitDraftNotice() {
    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/notice/draft/submit', {}).map(function (response) {
      return response;
    });
  }

  /**
   * 청구 삭제
   */
  deleteNotice(param) {
    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/notice/delete', param).map(function (response) {
      return response;
    });
  }

  /******************************************************************
   * 청구항목관리
   *******************************************************************/

  /**
   * 청구항목관리 목록
   */
  getNoticeItem() {
    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/notice/item/example').map((response: Response) => {
      return response;
    });
  }

  /**
   * 청구항목 등록
   */
  createNoticeItem(noticeItem: NoticeTemplate) {
    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/notice/item/example', noticeItem).map(function (response) {
      return response;
    });
  }

  /**
   * 청구항목 삭제
   */
  deleteNoticeItem(param) {
    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/notice/item/example/delete', param).map(function (response) {
      return response;
    });
  }
}
