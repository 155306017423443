import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from "./authentication.service";

@Injectable()
export class JoinFilter implements CanActivate {

  constructor(private router: Router, private authenticationService: AuthenticationService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const clientId = sessionStorage.getItem('id');
    const status = sessionStorage.getItem('status');

    if (!clientId) {
      return true;
    }

    if (status !== '130002') {
      this.authenticationService.logout().subscribe(() => {}
      , err => {}
      , () => {
        this.router.navigate(['']);
      });

      return true;
    }

    this.router.navigate(['join']);
    return false;
  }
}
