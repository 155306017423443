import {Pipe, PipeTransform} from '@angular/core';

/**
 * 현금영수증 정보
 *
 * @author mljeong@finger.co.kr
 */
@Pipe({name: 'formatCashReceiptNoPipe'})
export class FormatCashReceiptNoPipe implements PipeTransform {

  transform(input: string): string {
    if (input == null) {
      return input;
    }

    if (input == '') {
      return input;
    }

    let input2 = input.replace('-', '');

    let length = input2.length;

    if (length == 18) {
      // 현금영수증카드번호 ( 18 ( 4-4-4-6 ) )
      let output = [];
      output.push(input2.substring(0, 4));
      output.push(input2.substring(4, 8));
      output.push(input2.substring(8, 12));
      output.push(input2.substring(12));

      return output.join('-');
    } else {
      // 이동전화 ( 10 ~ 11 ( 3-3-4, 3-4-4 ) )
      if (input2.charAt(0) == '0') {
        let n01x = ['010', '011', '016', '017', '018', '019'];
        for (let each of n01x) {
          if (input2.startsWith(each) && (input2.length == 11 || input2.length == 10)) {
            let length = input2.length;

            let output = [];
            output.push(input2.substring(0, 3));
            output.push(input2.substring(3, length - 4));
            output.push(input2.substring(length - 4));

            return output.join('-');
          } else {
            return input2;
          }
        }
      } else {
        // 사업자등록번호 ( 10 ( 3-2-5 ) )
        let output = [];
        output.push(input2.substring(0, 3));
        output.push(input2.substring(3, 5));
        output.push(input2.substring(5));

        return output.join('-');
      }
    }
  }
}
