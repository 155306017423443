import {Pipe, PipeTransform} from '@angular/core';

/**
 * 계좌번호
 *
 * @author wisehouse@finger.co.kr
 */
@Pipe({name: 'formatNonghyupAccountNo'})
export class FormatNonghyupAccountNoPipe implements PipeTransform {

  transform(input: string): string {
    if (input == null) {
      return input;
    }
    let input2 = input.replace('-', '');

    let length = input2.length;

    let output = [];
    switch (length) {
      case 11:
        // 3, 2, 5, 1
        output.push(input2.substring(0, 3));
        output.push(input2.substring(3, 5));
        output.push(input2.substring(5, 10));
        output.push(input2.substring(10));
        break;
      case 12:
        // 4, 2, 4, 1
        output.push(input2.substring(0, 4));
        output.push(input2.substring(4, 6));
        output.push(input2.substring(6, 10));
        output.push(input2.substring(10));
        break;
      case 13:
        // 3, 4, 4, 1, 1
        output.push(input2.substring(0, 3));
        output.push(input2.substring(3, 7));
        output.push(input2.substring(7, 11));
        output.push(input2.substring(11, 12));
        output.push(input2.substring(12));
        break;
      case 14:
        // 이건 안씀
        // 6, 2, 5, 1

        // 3, 4, 4, 2, 1 => 3, 4, 4, 3
        output.push(input2.substring(0, 3));
        output.push(input2.substring(3, 7));
        output.push(input2.substring(7, 11));
        output.push(input2.substring(11));
        break;
      default:
        output.push(input2);
    }

    return output.join('-');
  }
}
