import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import 'rxjs/add/operator/map';

import {ApiService} from './api.service';

import {Code} from "../../_models/common/code.model";

/**
 * 공통 서비스
 * @author mlejong@finger.co.kr
 */
@Injectable()
export class CommonService {
  constructor(private http: HttpClient, private apiService: ApiService) {
  }

  /**
   * 메뉴
   */
  getAuthenticMenu(id) {
    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/sitemap/' + id);
  }

  /**
   * 코드
   */
  getCodeList(groupCode: string) {
    return this.http.get<Code[]>(this.apiService.APIURL + this.apiService.APIVERSION + '/code/' + groupCode);
  }

  /**
   * 스크롤 초기화
   */
  resetScroll() {
    return window.scrollTo(0, 0);
  }
}

