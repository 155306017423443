import {Component} from '@angular/core';
import {Router} from "@angular/router";
import swal from 'sweetalert2';

import {AuthenticationService} from "../_service/authentic/authentication.service";

import {ParentMenu} from "../_models/common/menu.model";
import {CommonService} from "../_service/common/common.service";

declare var $: any;
declare var showModal: any;

@Component({
  moduleId: module.id,
  selector: 'afterLogin',
  templateUrl: 'afterLogin.component.html'
})

export class AfterLoginComponent {
  id: string;
  name: string;
  url: string;

  authenticMenu: ParentMenu[];

  constructor(private router: Router, private authenticationService: AuthenticationService, private commonService: CommonService) {
    this.getAuthenticCode();
    this.getAuthenticMenu();
  }

  getAuthenticCode() {
    return sessionStorage.getItem('status') == '130003' ? true : false;
  }

  getAuthenticMenu() {
    this.commonService.getAuthenticMenu('').subscribe(
      (data) => {
        this.authenticMenu = data['children'];
      }
    )
  }

  getName() {
    return sessionStorage.getItem('userName');
  }

  logout() {
    this.authenticationService.logout().subscribe(data => {
        this.router.navigate(['']);
      }, (error) => {
        const message = error['error']['message'];
        const html = message.replace('\n', '<p>');

        swal({
          html: html,
          type: 'info',
          confirmButtonText: '확인'
        });

        return;
      }
      , () => {

        swal({
          text: "정상적으로 로그아웃 되셨습니다.",
          type: "success",
          confirmButtonText: "확인"
        });
      }
    );
  }

  onClose() {
    $('#sitemapModal .btn-close').click();
  }
}
