// 문자메시지 신청
export class MsgInvoiceApply {
  contactNo: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

// 문자메시지 안내문구 템플릿
export class MsgTemplate {
  templateNo: number;
  name: string;
  title: string;
  content: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

// 문자메시지 고지 연락처, 고객명 리스트
export class LoadMsgContactList {
  selected: boolean;
  typeCode: string;
  id: string;
  consumerContactNo: string;
  name: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

// 문자메시지 발송
export class SendMsg {
  title: string;
  content: string;
  messageList: MessageList[] = [];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class MessageList {
  typeCode: string;
  id: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
