import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {CommonService} from '../../../_service/common/common.service';
import {Meta, Title} from '@angular/platform-browser';

declare var $: any;
declare var showModal: any;

@Component({
  moduleId: module.id,
  selector: 'apply-success',
  templateUrl: 'login.apply-success.component.html'
})

export class LoginApplySuccessComponent {
  constructor(private router: Router,
              private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle('SOHO가상계좌 - 서비스이용신청완료');
    this.metaService.updateTag({
      name: 'title',
      content: this.titleService.getTitle()
    });
  }

  goGuideApply() {
    this.applySuccessModalClose();
    this.router.navigate(['/misc/guide-apply']);
  }

  applySuccessModalClose() {
    $('#applySuccessClient .btn-close').click();
  }
}
