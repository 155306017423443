import {Component} from '@angular/core';

declare var $:any;

@Component({
  moduleId: module.id,
  selector: 'termTab2',
  templateUrl: 'termTab2.component.html'
})

export class TermTab2Component {
  constructor() {
    window.scrollTo(0, 0);
  }
}
