import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';

import {environment} from '../../../environments/environment';

const APIURL = environment.APIURL;
const APIVERSION = environment.APIVERSION;

/**
 * 서버
 */
@Injectable()
export class ApiService {
  APIURL: string;
  APIVERSION: string;

  constructor() {
    this.APIURL = APIURL;
    this.APIVERSION = APIVERSION;
  }
}
