import {
  Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,
  ViewChild
} from '@angular/core';

import * as moment from 'moment';

declare var $: any;

/**
 * 날짜 선택기
 *
 * @author wisehouse@finger.co.kr
 */
@Component({
  moduleId: module.id,
  selector: 'date-picker',
  templateUrl: 'date-picker.component.html'
})

export class DatePickerComponent implements OnInit, OnChanges {
  @ViewChild('input')
  private input: ElementRef;

  @Input('date')
  private date: Date;

  @Output('dateChange')
  private dateChange: EventEmitter<Date> = new EventEmitter();

  inputDate: string;

  format = 'YYYY. M. D.';

  constructor() {
    this.date = null;
    this.inputDate = null;
  }

  ngOnInit() {
    const that = this;

    $(that.input.nativeElement).dateRangePicker({
      format: 'YYYY. M. D.',
      autoClose: true,
      singleDate: true,
      singleMonth: true,
      language: 'ko'
    }).bind('DRPEx-change', function (event, object) {
      that.inputDate = moment(object.date).format(that.format);
      that.changeDate();
    }).bind('DRPEx-opened', function () {
      $(this).dateRangePicker({
        format: 'YYYY. M. D.',
        autoClose: true,
        singleDate: true,
        singleMonth: true,
        language: 'ko'
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.date = changes.date.currentValue;

    if (this.date == null) {
      this.inputDate = '';
    } else {
      this.inputDate = moment(this.date).format(this.format);
    }
  }

  blur() {
    this.changeDate();
    if (this.date == null) {
      this.inputDate = '';
    } else {
      this.inputDate = moment(this.date).format(this.format);
    }
  }

  changeDate() {
    const m = moment(this.inputDate, 'YYYY. M. D.');

    if (m.isValid()) {
      this.date = m.toDate();
    } else {
      this.date = null;
    }
    this.emit();
  }

  emit() {
    this.dateChange.emit(this.date);
  }
}
