import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {ApiService} from '../common/api.service';

import 'rxjs/add/operator/map';

/**
 * 고객센터
 */

@Injectable()
export class MiscService {
  constructor(private http: HttpClient, private apiService: ApiService) {
  }

  /**
   * 카테고리 조회
   */
  public getCategoryList() {
    const params = new HttpParams();

    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/service/faq/category', {
      params: params
    });
  }

  /**
   * 자주하는 질문 조회
   */
  public getFaqList(category: string) {
    const params = new HttpParams();

    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/service/faq?' + category, {
      params: params
    });
  }

  /**
   * 공지사항 조회
   */
  public getNoticeList(searchParam) {
    const params = new HttpParams();

    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/service/notice?' + searchParam, {
      params: params
    });
  }

  /**
   * 공지사항 내용 조회
   */
  public getNoticeVeiw(id: number) {
    const params = new HttpParams();

    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION  + '/service/notice/' + id, {
      params: params
    });
  }
}
