import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import 'rxjs/Rx';

import {ApiService} from './api.service';
import {Client} from "../../_models/common/client.model";

@Injectable()
export class ClientApplyService {
  constructor(private http: HttpClient, private apiService: ApiService) {
  }

  /**
   * 서비스 이용신청
   */
  public clientApply(client: Client) {
    // client.vaPartnerId = '31000132';
    // client.paymentTypeCd = '120002';
    // client.periodValidTypeCd = '1K0002';
    // client.channelCd = '1M0001';

    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/service/client', client).map(function (response) {
      return response;
    });
  }
}
