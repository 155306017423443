import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {DatePickerComponent} from './date-picker/date-picker.component';
import {PaginateComponent} from './paginate/paginate.component';
import {YearMonthComponent} from './yearMonth/yearMonth.component';
import {NumberOnlyComponent} from "./numberOnly/numberOnly.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [
    DatePickerComponent
    , PaginateComponent
    , YearMonthComponent
    , NumberOnlyComponent
  ],
  exports: [
    DatePickerComponent
    , PaginateComponent
    , YearMonthComponent
    , NumberOnlyComponent
  ]
})

export class ComponentModule {
}
