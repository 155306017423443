// imports
import {APP_BASE_HREF, CommonModule, HashLocationStrategy, LocationStrategy} from "@angular/common";
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {COMPOSITION_BUFFER_MODE, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {routing} from './app.routing';
import {ComponentModule} from "./_components/component.module";
import {PipeModule} from "./_pipes/pipe.module";
import {SlideshowModule} from "ng-simple-slideshow";
import {NgDaumAddressModule} from 'ng2-daum-address';

// declarations
import {AppComponent} from './app.component';
import {LoginComponent} from "./contents/login/login.component";
import {BeforeLoginComponent} from "./top/beforeLogin.component";
import {AfterLoginComponent} from "./top/afterLogin.component";
import {TopComponent} from './top/top.component';
import {BottomComponent} from './bottom/bottom.component';
import {TermTab1Component} from "./bottom/termTab1/termTab1.component";
import {TermTab2Component} from "./bottom/termTab2/termTab2.component";
import {TermTab3Component} from "./bottom/termTab3/termTab3.component";
import {LoginApplyComponent} from "./contents/login/apply/login.apply.component";
import {LoginApplySuccessComponent} from "./contents/login/apply/login.apply-success.component";
import {DashboardComponent} from "./contents/dashboard/dashboard.component";
import {Error404Component} from "./error/error404.component";

// providers
import {Globals} from "./_service/common/globals";

import {AuthGuard} from "./_service/authentic/auth.guard";
import {JoinFilter} from "./_service/authentic/join.filter";
import {LogoutFilter} from "./_service/authentic/logout.filter";
import {AuthenticationService} from "./_service/authentic/authentication.service";
import {ApiService} from "./_service/common/api.service";
import {CommonService} from "./_service/common/common.service";
import {ApplyService} from "./_service/authentic/apply.service";
import {MeService} from "./_service/common/me.service";
import {ClientApplyService} from "./_service/common/clientApply.service";

import {ConsumerService} from "./_service/consumer/consumer.service";
import {NoticeService} from "./_service/receipt/notice.service";
import {ReceiptService} from "./_service/receipt/receipt.service";
import {PaymentService} from "./_service/receipt/payment.service";
import {CashReceiptService} from "./_service/receipt/cashReceipt.service";
import {MessageInvoiceService} from "./_service/receipt/messageInvoice.service";

import {RouterEventReset} from "./_service/common/router.eventReset";
import {AccountService} from "./_service/common/account.service";
import {ServiceSupportService} from "./_service/common/serviceSupport.service";
import {BannerPopupService} from "./_service/common/banner-popup.service";

import {ErrorInterceptor} from "./_interceptor/error.interceptor";
import {RequestInterceptor} from "./_interceptor/request.interceptor";
import {ClientInvoiceTemplateService} from './_service/client-invoice-template.service';
import {ClientImageService} from './_service/client-image.service';
import {ClientMessageTemplateService} from './_service/client-messaage-template.service';
import {ClientFileService} from './_service/client-file.service';
import {ClientMessageSourceService} from './_service/client-message-source.service';
import {ClientMessageService} from './_service/client-message.service';

@NgModule({
  imports: [
    CommonModule
    , BrowserModule
    , FormsModule
    , ReactiveFormsModule
    , HttpClientModule
    , routing
    , ComponentModule
    , PipeModule
    , SlideshowModule
    , NgDaumAddressModule
  ],
  declarations: [
    AppComponent
    , LoginComponent
    , BeforeLoginComponent
    , AfterLoginComponent
    , TopComponent
    , BottomComponent
    , TermTab1Component
    , TermTab2Component
    , TermTab3Component
    , LoginApplyComponent
    , LoginApplySuccessComponent
    , DashboardComponent
    , Error404Component
  ],
  providers: [
    Globals
    , AuthGuard
    , JoinFilter
    , LogoutFilter
    , AuthenticationService
    , ApiService
    , CommonService
    , ApplyService
    , MeService
    , ClientApplyService
    , ConsumerService
    , NoticeService
    , ReceiptService
    , PaymentService
    , CashReceiptService
    , MessageInvoiceService
    , RouterEventReset
    , AccountService
    , ServiceSupportService
    , BannerPopupService
    , ClientInvoiceTemplateService
    , ClientMessageTemplateService
    , ClientMessageService
    , ClientImageService
    , ClientFileService
    , ClientMessageSourceService
    , {provide: COMPOSITION_BUFFER_MODE, useValue: false}
    , {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true}
    , {provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true}
    , {provide: LocationStrategy, useClass: HashLocationStrategy}
    , {provide: APP_BASE_HREF, useValue: '/'}
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
