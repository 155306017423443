/**
 * 요금제
 */

export class Plan {
  planId: number = 0;
  id: number = 0;
  name: string = '';
  accountFee: number = 0;
  baseCharge: number = 0;
  freeAccountCount: number = 0;
  freePaymentCount: number = 0;
  lmsFee: number = 0;
  paymentFee: number = 0;
  smsFee: number = 0;
}

/**
 * 계좌
 */
class AccountList {
  accountNo: string;
  bankCd: string;
  name: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

/**
 * 이용기관
 */
export class Client {
  submitDt: string;
  id: string;
  vaBankId: string;
  name: string;
  identityNo: string;
  ownerName: string;
  contactNo: string;
  postalCode: string;
  address: string;
  businessType: string;
  businessKind: string;
  taxInvoiceEmail: string;
  usage: string;
  managerName: string;
  managerOfficeNo: string;
  managerContactNo: string;
  managerEmail: string;
  accountList: AccountList[] = [new AccountList(), new AccountList(), new AccountList()];
  planId: number;
  amountValidTypeCd: string = '1J0004';
  /**
   *  1J0001  1J  통지
   *  1J0002  1J  승인 / 단위 없음
   *  1J0003  1J  승인 / 청구 항목 단위
   *  1J0004  1J  승인 / 청구 단위
   */
  vaOwnerNameTypeCd: string = '1N0002';
  vaOwnerNamePrefix: string = '';
  useCashReceipt: Boolean = false;
  taxFree: Boolean = false;
  useAutoCashReceiptForVaPayment: Boolean = false;
  useAutoCashReceiptForManualPayment: Boolean = false;
  agree: boolean;
  vaPartnerId: string;
  paymentTypeCd: string;
  periodValidTypeCd: string;
  channelCd: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
