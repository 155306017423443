import {Pipe, PipeTransform} from '@angular/core';

/**
 * 전화번호 출력 포맷
 *
 * @author wisehouse@finger.co.kr
 */
@Pipe({name: 'formatContactNo'})
export class FormatContactNoPipe implements PipeTransform {

  transform(input: string): string {
    if (input == null) {
      return input;
    }
    let input2 = input.replace('-', '');

    // 이동전화
    let n01x = ['010', '011', '016', '017', '018', '019'];
    for (let each of n01x) {
      if (input2.startsWith(each) && (input2.length == 11 || input2.length == 10)) {
        let length = input2.length;

        let output = [];
        output.push(input2.substring(0, 3));
        output.push(input2.substring(3, length - 4));
        output.push(input2.substring(length - 4));

        return output.join('-');
      }
    }

    // 기간통신사업자 공통부가서비스
    let n1xxx = ['1588', '1577', '1899', '1544', '1644', '1661', '1566', '1600', '1670', '1599', '1877', '1855', '1800'];
    for (let each of n1xxx) {
      if (input2.startsWith(each) && input2.length == 8) {
        let output = [];
        output.push(input2.substring(0, 4));
        output.push(input2.substring(4));

        return output.join('-');
      }
    }

    // 지역 번호
    let n0xx = ['02', '031', '032', '033', '041', '042', '043', '044', '051', '052', '053', '054', '055', '061', '062', '063', '064'];
    for (let each of n0xx) {
      if (!input2.startsWith(each)) {
        continue;
      }
      let bodyLength = input2.length - each.length;
      if (bodyLength == 8 || bodyLength == 7) {
        let output = [];
        output.push(input2.substring(0, each.length));
        output.push(input2.substring(each.length, input2.length - 4));
        output.push(input2.substring(input2.length - 4));

        return output.join('-');
      }
    }

    return input;
  }
}
