function showModal(id) {
  $('.modal' + id).addClass('active');
  $('body').addClass('modal-opened');
}


function hideModal(id) {
  $('.modal' + id).removeClass('active');
  if ($('.modal.active').length == 0) {
    $('body').removeClass('modal-opened');
  }
}

function showTab(id) {
  $('.tabs .tab-menu *[data-tab-target="' + id + '"]').closest('li').addClass('active').siblings().removeClass('active');
  $('.tabs .tab-content' + id).addClass('active').siblings().removeClass('active');
}

$(document).on('click', '*[data-modal-hide="true"]', function (e) {
  e.preventDefault();
  var id = $(this).attr('data-modal-target');
  hideModal(id);
});

$(document).on('click', '*[data-modal-show="true"]', function (e) {
  e.preventDefault();
  var id = $(this).attr('data-modal-target');
  showModal(id);
});


$(document).on('click', '*[data-tab-show="true"]', function (e) {
  e.preventDefault();
  var id = $(this).attr('data-tab-target');
  showTab(id);
});


$(document).on('click', '.file-input .btn-upload', function (e) {
  e.preventDefault();
  $(this).siblings('input[type="file"]').trigger('click');
});

$(document).on('change', '.file-input input[type="file"]', function (e) {
  e.preventDefault();
  if (this.files.length > 0) {
    var file = this.files[0];
    $(this).siblings('input[type="text"]').val(file.name);
  } else {
    $(this).siblings('input[type="text"]').val('');
  }
});


$(function () {
  $(window).on('resize', function () {
    checkSubPageBlock();
  }).trigger('resize');

  function checkSubPageBlock() {
    if ($('#main .left-area').length == 0) return;
    var leftAreaHeight = $('#main .left-area').innerHeight();
    var rightAreaHeight = $('#main .right-area').innerHeight();
    var subPageHeight = Math.max(leftAreaHeight, rightAreaHeight);
    $('#main .left-area, #main .right-area').css('min-height', subPageHeight);
  }
});


// 사용한 datepicker에서 log2를 사용하는데 ie에서 오류가 나기 때문에 따로 function을 정의
Math.log2 = Math.log2 || function (x) {
  return Math.log(x) * Math.LOG2E;
};
