import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import swal from 'sweetalert2';

import {AuthenticationService} from '../../_service/authentic/authentication.service';
import {ApiService} from "../../_service/common/api.service";
import {ApplyService} from "../../_service/authentic/apply.service";
import {ServiceSupportService} from "../../_service/common/serviceSupport.service";
import {BannerPopupService} from '../../_service/common/banner-popup.service';

import * as moment from 'moment';
import {CommonService} from '../../_service/common/common.service';
import {Meta, Title} from '@angular/platform-browser';

declare var $: any;
declare var showModal: any;

@Component({
  moduleId: module.id,
  templateUrl: 'login.component.html'
})

export class LoginComponent implements OnInit {
  loading = false;
  returnUrl: string;

  username: string;
  password: string;

  id: string;
  name: string;
  status: string;

  contactNoList: any[] = [];

  onVerification: boolean;
  verificationDate: Date;
  remainTime: Date;
  userContactNo: string;
  passcode: string;
  verificationId: number;
  expireDate: number;

  getBannerList: any;

  imageSources: string[];
  bannerStatus: boolean;
  bannerUrl: string[];

  getPopupList: any;

  questionerName: string;
  questionerContact: string;

  constructor(private route: ActivatedRoute, private router: Router
    , private authenticationService: AuthenticationService
    , private apiService: ApiService
    , private serviceSupportService: ServiceSupportService
    , private applyService: ApplyService
    , private bannerpopupservice: BannerPopupService,
              private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle('SOHO가상계좌');
    this.metaService.updateTag({
      name: 'title',
      content: this.titleService.getTitle()
    });
    this.username = '';
    this.password = '';
    this.status = '';

    this.bannerStatus = false;
    this.bannerUrl = [];
    this.imageSources = [];

    this.questionerName = '';
    this.questionerContact = '';
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';

    if (this.returnUrl == '/') {
      this.returnUrl = '/dashboard';
    }

    this.getBanner();
    this.getPopup();
  }

  /**
   * 로그인
   */
  login() {
    this.loading = true;

    if (this.username == '') {
      swal({
        text: '아이디를 입력해주세요.',
        type: "info",
        confirmButtonText: "확인"
      });

      return;
    }

    if (this.password == '') {
      swal({
        text: '비밀번호를 입력해주세요.',
        type: "info",
        confirmButtonText: "확인"
      });

      return;
    }

    if (this.username != '' || this.password != '') {
      this.authenticationService.login(this.username, this.password)
        .subscribe(
          (data) => {
          }, (err) => {
            const message = err['error']['message'];
            const html = message.replace('\n', '<p>');

            swal({
              html: html,
              type: 'info',
              confirmButtonText: '확인'
            });

            return;
          }, () => {
            this.authenticationService.loginIndex().subscribe(data => {
              sessionStorage.setItem('id', data['id']);
              sessionStorage.setItem('sessionUserId', data['id']);
              sessionStorage.setItem('userName', data['name']);
              sessionStorage.setItem('status', data['status']['id']);
              sessionStorage.setItem('role', data['role']);

              this.status = data['status']['id'];

              if (this.status === '130003') {

                this.id = data['id'];
                this.name = data['name'];

                this.router.navigate([this.returnUrl]);
              }

              if (this.status === '130002') {
                this.id = data['id'];
                this.name = data['name'];

                this.applyService.getStep1().subscribe(
                  (data) => {
                    this.contactNoList = data['contactNoList'];
                    this.userContactNo = data['contactNoList']['0'];
                  }
                )
              }
            });

            this.password = '';
          }
        );
    }
  }

  sendVerificationMessage() {
    const contactNo = this.userContactNo;

    this.applyService
      .postStep1(contactNo)
      .subscribe(
        data => {
          this.verificationId = data['verifyId'];
          this.expireDate = data['expireDate'];

          this.startTimer(this.expireDate);
        }, err => {
          const message = err['error']['message'];
          const html = message.replace('\n', '<p>');

          swal({
            html: html,
            type: 'info',
            confirmButtonText: '확인'
          });

          return;
        }, () => {
          swal({
            text: "인증 문자가 요청되었습니다.",
            type: "success",
            confirmButtonText: "확인"
          });
        }
      );
  }

  submitPasscode() {
    this.passcode = this.passcode || '';

    if (this.passcode.length !== 6) {
      swal({
        text: "인증번호를 입력하여 주시기 바랍니다.",
        type: "info",
        confirmButtonText: "확인"
      });
      return;
    }

    this.applyService.postStep2(this.verificationId, this.passcode).subscribe(data => {
      this.onVerification = false;
    }, (err) => {
      const message = err['error']['message'];
      const html = message.replace('\n', '<p>');

      swal({
        html: html,
        type: 'info',
        confirmButtonText: '확인'
      });

      return;
    }, () => {
      swal({
        text: "인증에 성공하였습니다.",
        type: "success",
        confirmButtonText: "확인"
      });

      this.router.navigate(['join']);
    });
  }

  startTimer(expireDate) {
    this.onVerification = true;
    this.verificationDate = new Date();

    this.computeRemainTime();
  }

  computeRemainTime() {
    const that = this;
    const THREE_MINUTES = 3 * 60 * 1000;

    setTimeout(function () {
      if (!that.onVerification) {
        return;
      }

      that.remainTime = moment(that.verificationDate.getTime() + THREE_MINUTES - new Date().getTime()).toDate();

      if (that.remainTime.getTime() < 0) {
        that.onVerification = false;
        that.verificationDate = null;
        that.verificationId = null;

        swal({
          text: "인증시간이 지났습니다.",
          type: "warning",
          confirmButtonText: "확인"
        });
        return;
      }

      that.computeRemainTime();
    }, 100);
  }

  /**
   * 배너
   */
  getBanner() {
    this.bannerpopupservice
      .getBannerList()
      .subscribe(
        (data) => {
          this.getBannerList = data['itemList'];
          const reSources = [];
          for (let i = 0; i < this.getBannerList.length; i++) {
            reSources.push(this.apiService.APIURL + this.apiService.APIVERSION + '/service/image/' + data['itemList'][i].image.id + '/load');
          }
          this.imageSources = reSources;
          this.bannerStatus = true;
        }
      );
  }

  /**
   * 팝업
   */
  getPopup() {
    this.bannerpopupservice
      .getPopupList()
      .subscribe(
        (data) => {
          this.getPopupList = data['itemList'];

          for (let i = 0; i < this.getPopupList.length; i++) {
            const width = data['itemList'][i].image.width;
            const hegith = data['itemList'][i].image.height + 40;
            const linkUrl = data['itemList'][i].url;

            if (localStorage.getItem('popupStatus-' + i) == 'exit') {

            } else {
              if (data['itemList'].size > 0) {
                const OpenWindow = window.open('', '_blank', 'width=' + width + ', height=' + hegith + ', status=no, menubars=no, scrollbars=auto');

                OpenWindow.document.write('<script>\nfunction onLocalStorage() {\n' +
                  '    localStorage.setItem(\'popupStatus-' + i + '\', \'exit\');\n' +
                  '    self.close();\n' +
                  '}\n</script>' +
                  '<style>\nbody {margin: 0;}' +
                  '.bottomDiv { width: calc( 100% - 20px ); font-size: 10pt; text-align: right; border-top: 1px solid #b5b5b5; padding: 10px; }\n' +
                  '.btn { width: 100%; height: 100%; font-size: 10pt; border-radius: 3px; color: #fff; font-weight: bold; padding: 3px 5px; cursor: pointer; }\n' +
                  '.btn-blue { background: #4081cb; margin-right: 10px; }\n' +
                  '.btn-grey { background: #353a3f; }</style>' +
                  '<a href=\'' + linkUrl + '\' target=\'_blank\'><img  src=' + this.apiService.APIURL + this.apiService.APIVERSION + '/service/image/' + data['itemList'][i].image.id + '/load></a>' +
                  '<div class=\'bottomDiv\'><span class=\'btn btn-blue\' onclick=\'onLocalStorage();\'>더이상 보지않기</span><sapn class=\'btn btn-grey\' onclick=\'self.close();\'>닫기</sapn></div>');
              }
            }
          }
        }
      );
  }

  /**
   * 납부자 서비스
   */
  loginAsConsumer() {
    swal({
      text: "서비스 준비 중입니다.",
      type: "info",
      confirmButtonText: "확인"
    });
  }

  /**
   * 상담하기
   */
  onQanPopup() {
    showModal('#qnaModal');
  }

  onCreateQna() {
    if (this.questionerName == '') {
      swal({
        text: "이름을 입력해 주세요.",
        type: "info",
        confirmButtonText: "확인"
      });
      return;
    }

    if (this.questionerContact == '') {
      swal({
        text: "휴대전화번호를 입력해 주세요.",
        type: "info",
        confirmButtonText: "확인"
      });
      return;
    }

    if (!$('#agreeChk').is(':checked')) {
      swal({
        text: "개인정보 수집 및 이용에 동의하여 주시기 바랍니다.",
        type: "info",
        confirmButtonText: "확인"
      });
      return;
    }

    this.serviceSupportService.createServicSupport(this.questionerName, this.questionerContact).subscribe(response => {
      swal({
        text: "접수되었습니다.",
        type: "success",
        confirmButtonText: "확인"
      });

      this.onQnaClose();
    });
  }

  onCancelQna() {
    this.questionerName = '';
    this.questionerContact = '';

    swal({
      text: "접수가 취소되었습니다.",
      type: "success",
      confirmButtonText: "확인"
    });

    this.onQnaClose();
  }

  onQnaClose() {
    this.questionerName = '';
    this.questionerContact = '';

    $('#qnaModal .btn-close').click();
  }

  /**
   * 신청하기
   */
  onApplyPopup() {
    showModal('#applyClient');
  }
}
