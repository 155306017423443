import {Component, OnInit} from '@angular/core';

declare var $: any;

@Component({
  moduleId: module.id,
  selector: 'error404',
  templateUrl: 'error404.component.html'
})

export class Error404Component implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
}
