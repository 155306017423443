import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import 'rxjs/add/operator/do';
import swal from 'sweetalert2';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).do(event => {
    }, response => {
      if (response instanceof HttpErrorResponse == false) {
        return;
      }

      if (response.status === 401) {
        sessionStorage.clear();

        location.hash = '#/?returnUrl=' + encodeURIComponent(location.hash.substring(1));

        swal({
          text: '세션이 만료되어 로그인 페이지로 이동합니다.',
          type: 'info',
          confirmButtonText: '확인'
        });

        return;
      }
    });
  }
}
