import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import 'rxjs/add/operator/map';

import {ApiService} from '../common/api.service';

@Injectable()
export class AccountService {
  constructor(private http: HttpClient, private apiService: ApiService) {
  }

  /**
   * 집급계좌 조회 ( 입금통장명 등 )
   */
  public getAccountNameList() {
    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/account').map((response: Response) => {
      return response;
    });
  }
}

