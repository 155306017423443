import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import 'rxjs/add/operator/map';
import {ApiService} from './common/api.service';

export class ClientMessageTemplate {
  templateNo: number;
  name: string;
  title: string;
  content: string;
}

export class Image {
  id: number;
  name = '';

}

/**
 * 고지서 템플릿
 *
 * @author wisehouse@finger.co.kr
 */
@Injectable()
export class ClientMessageTemplateService {
  constructor(private http: HttpClient, private apiService: ApiService) {

  }

  /**
   * 목록 조회
   *
   */
  public getClientMessageTemplateList() {
    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/message-template');
  }

  modifyClientMessageTemplate(clientMessageTemplate: ClientMessageTemplate) {
    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/message-template/' + clientMessageTemplate.templateNo + '/modify', clientMessageTemplate);
  }

  changeClientMessageSetting(useAutoNoticeMessage: boolean, noticeMessageTemplateNo: number, useAutoPaymentMessage: boolean, paymentMessageTemplateNo: number) {
    const setting = {
      useAutoNoticeMessage: useAutoNoticeMessage,
      noticeMessageTemplateNo: noticeMessageTemplateNo,
      useAutoPaymentMessage: useAutoPaymentMessage,
      paymentMessageTemplateNo: paymentMessageTemplateNo
    };

    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/message-template/change-setting', setting);
  }

  preview(template: string) {
    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/message-template/preview', {
      params : {
        text: template
      }
    });
  }
}

