import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Paginate} from '../../_models/common/paginate.model';

declare var $:any;

/**
 * 날짜 선택기
 *
 * @author wisehouse@finger.co.kr
 */
@Component({
  moduleId: module.id,
  selector: 'paginate',
  templateUrl: 'paginate.component.html'
})

export class PaginateComponent implements OnInit {
  @Input('paginate')
  public paginate: Paginate;

  @Output('onPageClick')
  public pageClick: EventEmitter<number> = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  onClick(pageNo: number) {
    this.pageClick.emit(pageNo);
  }
}
