import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {ApiService} from '../common/api.service';

import 'rxjs/add/operator/map';

@Injectable()
export class ReceiptService {
  constructor(private http: HttpClient, private apiService: ApiService) {
  }

  /**
   * 수납내역 조회
   */
  getReceiptList(searchParam) {
    const params = new HttpParams();

    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/receipt?' + searchParam, {
      params: params
    });
  }

  /**
   * 수납내역 상세보기
   */
  getReceiptInfo(id) {
    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/receiptItem/' + id);
  }

  /**
   * 수납이 있는 청구항목
   */
  getNoticeReceiptItem() {
    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/receiptItem/noticeItem');
  }

  /**
   * 항목별 수납내역
   */
  getItemReceipt(param) {
    const params = new HttpParams();

    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/receiptItem?' + param, {
      params: params
    });
  }
}

