import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import 'rxjs/add/operator/map';

import {ApiService} from '../common/api.service';

import {CashReceipt, CreateCashReceipt} from "../../_models/receipt/cashReceipt.model";

export class CashReceiptCreateExample {
  consumerIdentityNo: string;
  amount: number;
}

@Injectable()
export class CashReceiptService {
  constructor(private http: HttpClient, private apiService: ApiService) {
  }

  /**
   * 현금 영수증 발급 상세보기
   */
  public getCashReceiptInfo(paymentId: string) {
    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/payment/' + paymentId);
  }

  /**
   * 현금영수증 발급 신청
   */
  public createCashReceipt(paymentId: string, example: CashReceiptCreateExample) {
    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/payment/' + paymentId + '/cash-receipt', example);
  }

  /**
   * 현금영수증 발급 취소
   */
  public cancelCashReceipt(paymentId: string) {
    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/payment/' + paymentId + '/cash-receipt/cancel', {});
  }

  /**
   * 현금영수증 요청 취소 ( 발급/취소 )
   */
  public cancelRequestCashReceipt(paymentId: string) {
    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/payment/' + paymentId + '/cash-receipt/cancel-request', {});
  }

}

