import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import 'rxjs/add/operator/map';
import {ApiService} from './common/api.service';
import {Observable} from 'rxjs';

export class Image {
  id: number;
  name = '';

}

/**
 * 이용기관 이미지 서비스
 *
 * @author wisehouse@finger.co.kr
 */
@Injectable()
export class ClientImageService {
  constructor(private http: HttpClient, private apiService: ApiService) {

  }

  public createImage(file: File): Observable<Image> {
    if (file == null) {
      return;
    }

    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.http.post<Image>(this.apiService.APIURL + this.apiService.APIVERSION + '/image', formData);
  }
}

