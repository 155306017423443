import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {ApiService} from '../common/api.service';

import 'rxjs/add/operator/map';

@Injectable()
export class PaymentService {
  constructor(private http: HttpClient, private apiService: ApiService) {
  }

  /**
   * 입금내역 조회
   */
  getPaymentList(searchParam) {
    const params = new HttpParams();

    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/payment?' + searchParam, {
      params: params
    });
  }

  /**
   * 현금영수증 발급목록 => getPaymentList와 형식이 많이 달라서 따로 만듬. useCashReceipt 감안해서 입금내역 조회
   */
  getPaymentList2(searchParam) {
    const params = new HttpParams();

    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/payment?' + searchParam, {
      params: params
    });
  }


  /**
   * 입금내역 상세보기
   */
  getPaymentInfo(id) {
    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/payment/' + id);
  }
}

