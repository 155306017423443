import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";

@Injectable()
export class RouterEventReset {
  constructor(private router: Router) {
  }

  /**
   * 현재 위치한 페이지 메뉴를 좌측 메뉴에서 재 진입 시 현재 화면 초기화
   */
  public getRouterEventReset() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        // this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });
  }
}
