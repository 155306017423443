import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import 'rxjs/add/operator/map';
import {ApiService} from './common/api.service';

export class ClientInvoiceTemplate {
  templateNo: number;
  name: string;
  title: string;
  content: string;
  consumerTitle: string;
  remark: string;
}

/**
 * 고지서 템플릿
 *
 * @author wisehouse@finger.co.kr
 */
@Injectable()
export class ClientInvoiceTemplateService {
  constructor(private http: HttpClient, private apiService: ApiService) {

  }

  /**
   * 목록 조회
   *
   */
  public getClientInvoiceTemplateList() {
    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/invoice-template');
  }

  modifyClientInvoiceTemplate(clientInvoiceTemplate: ClientInvoiceTemplate) {
    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/invoice-template/' + clientInvoiceTemplate.templateNo + '/modify', clientInvoiceTemplate);
  }

  deleteLogo() {
    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/invoice-template/delete-logo', {});
  }

  registerLogo(imageId: number) {
    if (!imageId) {
      return;
    }

    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/invoice-template/register-logo', {
      id: imageId
    });
  }

  modifyActiveClientInvoiceTemplate(defaultClientInvoiceTemplateNo: number) {
    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/invoice-template/' + defaultClientInvoiceTemplateNo + '/activate', {});
  }
}

