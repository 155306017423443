import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {ApiService} from '../common/api.service';

import 'rxjs/add/operator/map';

@Injectable()
export class AuthenticationService {
  constructor(private http: HttpClient, private apiService: ApiService) {
  }

  login(username: string, password: string) {
    const loginParam = {
      username: username,
      password: password,
      role: 'CLIENT_ADMIN'
    };

    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/login', loginParam).map((response: Response) => {
      this.loginIndex().subscribe(
        (data) => {});

      return response;
    }, err => err);
  }

  logout() {
    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/logout', '').map((response: Response) => {
      sessionStorage.clear();
      return response;
    });
  }

  loginIndex() {
    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION);
  }

  figerToClient(token) {
    const tokenParam = {
      token: token
    };

    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/login-as-client', tokenParam).map((response: Response) => {
      this.loginIndex().subscribe(
        (data) => {
          sessionStorage.setItem('id', data['id']);
          sessionStorage.setItem('sessionUserId', data['id']);
          sessionStorage.setItem('userName', data['name']);
          sessionStorage.setItem('status', data['status']['id']);
          sessionStorage.setItem('role', data['role']);
        }
      )
      return response;
    });
  }

}
