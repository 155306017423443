import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import 'rxjs/add/operator/map';

import {ApiService} from '../common/api.service';

import {SendMsg} from "../../_models/invoice/messageInvoice.model";

/**
 * 문자메시지 고지
 */

@Injectable()
export class MessageInvoiceService {
  constructor(private http: HttpClient, private apiService: ApiService) {
  }

  /**
   * 문자메시지 목록
   */
  getMessageList() {
    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/message-template');
  }

  /**
   * 문자메시지 신청
   */
  messageInvoiceApply(param) {
    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/notice/message/invoice/apply?' + param);
  }

  /**
   * 문자메시지 발송
   */
  sendMessageInvoice(sendMsg: SendMsg) {
    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/notice/message', sendMsg);
  }
}
