import {Plan} from "../common/client.model";

/**
 * 이용수수료 현황
 */
export class InvoiceCurrent {
  thisMonth: string;
  plan: Plan = new Plan();
  totalCharge: number;
  additionalPaymentCharge: number;
  additionalPaymentCount: number;
  smsCharge: number;
  smsCount: number;
  lmsCharge: number;
  lmsCount: number;
  vat: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
