import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import 'rxjs/add/operator/map';

import {ApiService} from './api.service';

/**
 * 전화 상담 예약
 */
@Injectable()
export class ServiceSupportService {
  constructor(private http: HttpClient, private apiService: ApiService) {
  }

  createServicSupport(questionerName: string, questionerContact: string) {
    const params = {
      consumerName: questionerName,
      consumerContactNo: questionerContact
    };

    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/service/support', params).map((response: Response) => {
      return response;
    });
  }
}

