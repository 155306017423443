import {User} from "../common/user.model";
import {Category} from "../common/category.model";

/**
 * 자주하는 질문
 */
export class FaqArticle {
  id: number;
  title: string;
  content: string;
  category: Category = new Category();

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

/**
 * 공지사항
 */
export class NoticeArticle {
  id: number;
  title: string;
  content: string;
  pinned: boolean;
  viewCount: number;
  contentType: string;
  fileList: File;
  createDate: Date = new Date();
  createUser: User = new User();

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

