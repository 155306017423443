import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {ApiService} from './api.service';

import 'rxjs/add/operator/map';

@Injectable()
export class BannerPopupService {
  constructor(private http: HttpClient, private apiService: ApiService) {
  }

  /**
   * 배너
   */
  public getBannerList() {
    const params = new HttpParams();

    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/service/banner', {
      params: params
    });
  }

  /**
   * 팝업
   */
  public getPopupList() {
    const params = new HttpParams();

    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/service/popup', {
      params: params
    });
  }
}
