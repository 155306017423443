import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Consumer} from '../../_models/consumer/consumer.model';
import {ApiService} from '../common/api.service';

import 'rxjs/add/operator/map';

@Injectable()
export class ConsumerService {
  constructor(private http: HttpClient, private apiService: ApiService) {
  }

  /******************************************************************
   * 고객 목록
   *******************************************************************/

  /**
   * 고객 목록 > 목록 조회
   */
  getConsumerList(searchParam) {
    const params = new HttpParams();

    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/consumer?' + searchParam, {
      params: params
    });
  }

  /**
   * 고객 목록 > 고객 상세보기
   */
  getConsumer(id) {
    const params = new HttpParams();

    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/consumer/' + id, {
      params: params
    });
  }

  /**
   * 고객 목록 > 고객 수정
   */
  modifyConsumer(consumer: Consumer) {
    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/consumer/' + consumer.id + '/modify', consumer).map(function (response) {
      return response;
    });
  }

  /**
   * 고객 목록 > 고객 단건 삭제 ( 코드값 130002 -> 130003 변경 )
   */
  deleteConsumer(id: string) {
    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/consumer/' + id + '/remove', {}).map(function (response) {
      return response;
    });
  }

  /**
   * 고객 목록 > 고객 선택 삭제 ( 코드값 130002 -> 130003 변경 )
   */
  deleteMultiConsumer(param) {
    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/consumer/remove', param).map(function (response) {
      return response;
    });
  }


  /******************************************************************
   * 고객 등록
   *******************************************************************/

  /**
   * 고객 등록 > 목록 조회
   */
  getConsumerTemporaryList(searchParam) {
    const params = new HttpParams();

    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/consumer/draft?' + searchParam, {
      params: params
    });
  }

  /**
   * 고객 등록 > 개별 고객 등록
   */
  createConsumer(consumer: Consumer) {
    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/consumer', consumer).map(function (response) {
      return response;
    });
  }

  /**
   * 고객 등록 > 고객 대량 등록
   */
  createAllConsumer() {
    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/consumer/draft/submit', {}).map(function (response) {
      return response;
    });
  }

  /**
   * 고객 등록 > 엑셀파일 양식 다운로드
   */

  /**
   * 고객 등록 > 엑셀파일 대량등록
   */
  uploadConsumerExcelFile(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/consumer/upload', formData).map(function (response) {
      return response;
    });
  }

  /**
   * 고객 등록 > 고객 임시 등록
   */
  createTemporaryConsumer(consumer: Consumer) {
    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/consumer', consumer).map(function (response) {
      return response;
    });
  }

  /**
   * 고객 등록 > 임시고객 단건 삭제 ( 데이터 삭제 )
   */
  deleteTemporaryConsumer(id) {
    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/consumer/' + id + '/delete', {}).map(function (response) {
      return response;
    });
  }

  /**
   * 고객 등록 > 임시고객 선택 삭제 ( 데이터 삭제 )
   */
  deleteTemporaryMultiConsumer(param) {
    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/consumer/delete', param).map(function (response) {
      return response;
    });
  }

  /**
   * 고객 등록 > 임시고객 전체 삭제 ( 데이터 삭제 )
   */
  deleteTemporaryAllConsumer() {
    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/consumer/draft/delete', {}).map(function (response) {
      return response;
    });
  }

  register(consumerId: number) {
    return this.http.post(this.apiService.APIURL + '/consumer/' + consumerId + '/register', {}).map(response => response);
  }
}
