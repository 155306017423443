import {Component, OnInit} from '@angular/core';

declare var $:any;

@Component({
    moduleId: module.id,
    selector: 'bottom',
    templateUrl: 'bottom.component.html'
})

export class BottomComponent implements OnInit {
    constructor() { }

    ngOnInit() {
    }
}
