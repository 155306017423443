export class Paginate {
  pageSize: number;
  pageGroupSize: number;
  totalItemCount: number;
  pageNo: number;

  constructor(totalItemCount: number, pageNo: number = 1, pageSize: number = 10, pageGroupSize: number = 10) {
    this.totalItemCount = totalItemCount;
    this.pageNo = pageNo;
    this.pageSize = pageSize;
    this.pageGroupSize = pageGroupSize;
  }

  public getFirst() {
    return 1;
  }

  public getLast() {
    return Math.ceil(this.totalItemCount / this.pageSize);
  }

  public getStart() {
    let pageGroupNo = Math.ceil(this.pageNo / this.pageGroupSize);
    return this.pageGroupSize * (pageGroupNo - 1) + 1;
  }

  public getEnd() {
    let pageGroupNo = Math.ceil(this.pageNo / this.pageGroupSize);
    let last = this.getLast();
    return Math.min(this.pageGroupSize * (pageGroupNo), last);
  }

  public getPrevious() {
    return Math.max(this.getFirst(), this.getStart() - 1);
  }

  public getNext() {
    return Math.min(this.getLast(), this.getEnd() + 1);
  }

  public getPageList() {
    let pageList = [];
    for (let i = this.getStart(); i <= this.getEnd(); i++) {
      pageList.push(i);
    }

    return pageList;
  }

  public setTotalItemCount(totalItemCount: number) {
    this.totalItemCount = totalItemCount;
  }

  public getPageOf(index: number) {
    return Math.floor((index / this.pageSize) + 1);
  }
}
