import {Component} from '@angular/core';

import {CommonService} from "../../_service/common/common.service";

import {Payment} from "../../_models/receipt/payment.model";
import {PaymentService} from "../../_service/receipt/payment.service";

import {MiscService} from "../../_service/misc/misc.service";

import {NoticeArticle} from "../../_models/misc/misc.model";
import {NoticeService} from "../../_service/receipt/notice.service";

import {InvoiceService} from "../../_service/invoice/invoice.service";
import {InvoiceCurrent} from "../../_models/invoice/InvoiceCurrent.model";

import * as moment from 'moment';
import {ApplyService} from '../../_service/authentic/apply.service';
import {Meta, Title} from '@angular/platform-browser';

declare var $: any;

@Component({
  moduleId: module.id,
  selector: 'dashboard',
  templateUrl: 'dashboard.component.html',
  providers: [MiscService, InvoiceService]
})

export class DashboardComponent {
  toYearMonth: string;

  monthlyNoticeCount: number = 0;
  paidNoticeCount: number = 0;
  payableNoticeCount: number = 0;
  partialNoticeCount: number = 0;

  paymentList: Payment[];
  totalPaymentItemConut: number;

  noticeArticle: NoticeArticle[];
  totalNoticeArticleItemConut: number;

  invoiceCurrent: InvoiceCurrent = new InvoiceCurrent();

  constructor(private commonService: CommonService, private noticeService: NoticeService, private paymentService: PaymentService, private miscService: MiscService, private invoiceService: InvoiceService,
              private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle('SOHO가상계좌 - 대시보드');
    this.metaService.updateTag({
      name: 'title',
      content: this.titleService.getTitle()
    });
    this.commonService.resetScroll();

    this.noticeService.getMaxMonth().subscribe(
      (data) => {
        this.toYearMonth = data['value'];

        if (data['value']) {
          this.toYearMonth = data['value'];
        } else {
          this.toYearMonth = moment(new Date()).format('YYYYMM');
        }

        this.getDashboard(this.toYearMonth);
      }
    );

    this.getPayment();
    this.getNotice();
    this.getInvoiceCurrent();
  }

  toYear(yearMonth) {
    const postDate = moment(yearMonth, 'YYYYMM');
    return moment(postDate).format('YYYY');
  }

  toMonth(yearMonth) {
    const postDate = moment(yearMonth, 'YYYYMM');
    return moment(postDate).format('M');
  }

  toDate(date) {
    const postDate = moment(date, 'YYYYMMDD');
    return moment(postDate).format('YYYY. M. D.');
  }

  toDateString(yearMonth) {
    const postDate = moment(yearMonth, 'YYYYMM');
    return moment(postDate).format('YYYY. M.');
  }

  /**
   * 대시보드
   */
  getPrev() {
    let postYM = moment(this.toYearMonth, 'YYYYMM').add(-1, 'month').format('YYYYMM');
    this.toYearMonth = moment(postYM, 'YYYYMM').format('YYYYMM');

    this.getDashboard(this.toYearMonth);
  }

  getNext() {
    let postYM = moment(this.toYearMonth, 'YYYYMM').add(1, 'month').format('YYYYMM');
    this.toYearMonth = moment(postYM, 'YYYYMM').format('YYYYMM');

    this.getDashboard(this.toYearMonth);
  }

  getDashboard(noticeMonth) {
    let params = [];
    params.push('noticeMonth=' + noticeMonth);

    this.noticeService.getNoticeList(params).subscribe(
      (data) => {
        this.monthlyNoticeCount = data['totalItemCount'];
        this.paidNoticeCount = data['paidNoticeCount'];
        this.payableNoticeCount = data['payableNoticeCount'];
        this.partialNoticeCount = data['partialNoticeCount'];
      }
    )
  }

  /**
   * 최근입금내역
   */

  getPayment() {
    const that = this;
    const searchParam = [];
    searchParam.push('pageNo=' + 1);
    searchParam.push('pageSize=' + 5);

    that.paymentService.getPaymentList(searchParam.join('&')).subscribe(
      (data) => {
        that.paymentList = data['itemList'];
        that.totalPaymentItemConut = data['totalItemCount'];

        const count = that.paymentList.length;

        if (that.paymentList.length > 0) {
          if (that.paymentList.length < 5) {
            for (let i = 0; i < 5 - count; i++) {
              that.paymentList.push(new Payment());
            }
          }
        }
      }
    );
  }

  /**
   * 공지사항
   */
  getNotice() {
    const that = this;
    const searchParam = [];
    searchParam.push('pageNo=' + 1);
    searchParam.push('pageSize=' + 5);

    that.miscService.getNoticeList(searchParam.join('&')).subscribe(
      (data) => {
        that.noticeArticle = data['itemList'];
        that.totalNoticeArticleItemConut = data['totalItemCount'];

        const count = that.noticeArticle.length;

        if (that.noticeArticle.length > 0) {
          if (that.noticeArticle.length < 5) {
            for (let i = 0; i < 5 - count; i++) {
              that.noticeArticle.push(new NoticeArticle());
            }
          }
        }
      }
    );
  }

  /**
   * 이용수수료 현황
   */
  getInvoiceCurrent() {
    this.invoiceService.getInvoiceCurrent().subscribe(
      (data: InvoiceCurrent) => {
        this.invoiceCurrent = data;
      }
    )
  }
}
