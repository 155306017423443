import {Code} from "../common/code.model";

/**
 * 입금
 */
export class Payment {
  id: string;
  consumerName: string;
  accountNo: string;
  consumerProperty1: string;
  consumerProperty2: string;
  consumerProperty3: string;
  consumerProperty4: string;
  noticeStatus: Code[] = [];
  noticeItemCount: number;
  paymentDate: Date = new Date();
  paymentDt: string;
  paymentOwnerName: string;
  paymentAmount: number;
  paymentPartner: Code;
  paymentMethod: Code;
  cashReceiptStatus: Code;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
