import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import 'rxjs/add/operator/map';
import {ApiService} from './common/api.service';
import {Observable} from 'rxjs';
import {Code} from '../_models/common/code.model';

export class ClientMessageSource {
  no: number;
  sourceId: string;
  fileId: number;
  status: Code;
}

export class ClientMessageSourceExample {
  sourceId: string;
  fileId: number;
}

/**
 * 이용기관 이미지 서비스
 *
 * @author wisehouse@finger.co.kr
 */
@Injectable()
export class ClientMessageSourceService {
  constructor(private http: HttpClient, private apiService: ApiService) {

  }

  public getClientMessageSourceList() {
    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/message-source');
  }

  public getDraftClientMessageSourceList() {
    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/message-source/draft');
  }

  public getActiveClientMessageSourceList() {
    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/message-source/active');
  }

  public createClientMessageSource(example: ClientMessageSourceExample): Observable<ClientMessageSource> {
    return this.http.post<ClientMessageSource>(this.apiService.APIURL + this.apiService.APIVERSION + '/message-source', example);
  }
}

