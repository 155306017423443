import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ApiService} from './api.service';

import 'rxjs/add/operator/map';
import {ConsumerProperty} from "../../_models/consumer/consumer.model";

@Injectable()
export class MeService {
  constructor(private http: HttpClient, private apiService: ApiService) {
  }

  /**
   * 내 정보
   */
  getIndex() {
    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION);
  }

  /**
   * 고객구분명 설정 조회 ( 고객구분값 설정 팝업 조회시 필요)
   */
  getConsumerPropertySetting() {
    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/consumer-property');
  }

  /**
   * 고객구분값 수정
   */
  modifyConsumerProperty(consumerProperties) {
    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/consumer-property/update', consumerProperties).map(function (response) {
      return response;
    });
  }

  /**
   * 고객구분값 조회 ( 고객구분값 사용여부에 따른 화면 출력용 )
   */
  getConsumerProperty() {
    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/consumer-property/active');
  }

  /**
   * 가상계좌 번호
   */
  getVirtualAccountUsageRate() {
    return this.http.get(this.apiService.APIURL + '/me/virtualAccount/usage');
  }

  /**
   * 가상계좌 수납 설정
   */
  modifyPaymentSetting(checkAmount: any, checkPeriod: any) {
    return this.http.put(this.apiService.APIURL + '/me/paymentSetting', {
      useCheckAmount: checkAmount == 'C1000001' || false,
      useCheckPeriod: checkPeriod
    });
  }

  increaseIntroduceCount() {
    const body = {};
    const options = {};
    options['responseType'] = 'text';
    this.http.put(this.apiService.APIURL + '/me/increase-introduce-count', body, options).subscribe();
  }

  /**
   * 사용중인 고지서
   */
  getDefaultInvoice() {
    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/invoice-template');
  }
}

