import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {Client, Plan} from "../../../_models/common/client.model";
import {PlanService} from "../../../_service/common/plan.service";

import {ClientApplyService} from "../../../_service/common/clientApply.service";

import swal from 'sweetalert2';

import * as moment from 'moment';
import {CommonService} from '../../../_service/common/common.service';
import {Meta, Title} from '@angular/platform-browser';

declare var $: any;
declare var showModal: any;

@Component({
  moduleId: module.id,
  selector: 'apply',
  templateUrl: 'login.apply.component.html',
  providers: [PlanService]
})

export class LoginApplyComponent implements OnInit {
  client: Client = new Client();
  plan: Plan;

  identityNo1: string;
  identityNo2: string;
  identityNo3: string;

  contactNo1: string;
  contactNo2: string;
  contactNo3: string;

  managerOfficeNo1: string;
  managerOfficeNo2: string;
  managerOfficeNo3: string;

  managerContactNo1: string;
  managerContactNo2: string;
  managerContactNo3: string;

  /**
   * 다음 우편번호
   */
  addrForm: FormGroup;

  daumAddressOptions = {
    class: ['btn', 'btn-solid', 'btn-grey', 'btn-address']
  };

  planItemList = {
    itemList: [],
    totalItemCount: 0,
    itemCount: 0
  };

  telNoList = ['02', '031', '032', '033', '041', '042', '043', '044', '051', '052', '053', '054', '055', '061', '062', '063', '064', '070', '080', '010', '011', '016', '017', '018', '019'];
  phoneNoList = ['010', '011', '016', '017', '018', '019'];

  constructor(private builder: FormBuilder, private planService: PlanService, private clientApplyService: ClientApplyService,
              private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle('SOHO가상계좌 - 서비스이용신청');
    this.metaService.updateTag({
      name: 'title',
      content: this.titleService.getTitle()
    });
    this.client.vaBankId = '';
    this.client.amountValidTypeCd = '1J0004';
    this.client.vaOwnerNameTypeCd = '1N0002';
    this.client.useCashReceipt = false;
    this.client.taxFree = false;
    this.client.useAutoCashReceiptForVaPayment = false;
    this.client.useAutoCashReceiptForManualPayment = false;
    this.client.agree = false;
    this.client.planId = 0;

    this.client.accountList[0].accountNo = '';
    this.client.accountList[0].name = '';
    this.client.accountList[1].accountNo = '';
    this.client.accountList[1].name = '';
    this.client.accountList[2].accountNo = '';
    this.client.accountList[2].name = '';

    this.contactNo1 = '02';
    this.managerOfficeNo1 = '02';
    this.managerContactNo1 = '010';

    /**
     * 다음 우편번호
     */
    this.addrForm = this.builder.group({
      postalCode: ['', [Validators.required]]
      , address: ['', [Validators.required]]
    });

    /**
     * 수수료 정보
     */
    this.planService.planList().subscribe(data => {
      this.planItemList['itemList'] = data['itemList'];
      this.planItemList['itemCount'] = data['itemCount'];
      this.planItemList['totalItemCount'] = data['totalItemCount'];
      this.plan = null;
    });
  }

  ngOnInit() {
  }

  setDaumAddressApi(data: any) {
    this.addrForm.patchValue({
      postalCode: data.zip,
      address: data.addr
    });
  }

  applyClient() {
    const that = this;

    that.client.submitDt = moment(new Date).format('YYYYMMDD');
    that.client.identityNo = that.identityNo1 + that.identityNo2 + that.identityNo3;
    that.contactNo1 == '없음' ? that.client.contactNo = that.contactNo2 + that.contactNo3 : that.client.contactNo = that.contactNo1 + that.contactNo2 + that.contactNo3;
    that.managerOfficeNo1 == '없음' ? that.client.managerOfficeNo = that.managerOfficeNo2 + that.managerOfficeNo3 : that.client.managerOfficeNo = that.managerOfficeNo1 + that.managerOfficeNo2 + that.managerOfficeNo3;
    that.client.managerContactNo = that.managerContactNo1 + that.managerContactNo2 + that.managerContactNo3;

    if (that.client.vaBankId == '') {
      swal({
        html: '제휴은행을 선택해 주세요.',
        type: "info",
        confirmButtonText: "확인"
      });

      return;
    }

    if (that.client.name == null) {
      swal({
        html: '이용기관명을 입력해주세요.',
        type: "info",
        confirmButtonText: "확인"
      });

      return;
    }

    if (that.client.identityNo == null) {
      swal({
        html: '사업자등록번호를 입력해주세요.',
        type: "info",
        confirmButtonText: "확인"
      });

      return;
    } else {
      if (that.client.identityNo.length != 10) {
        swal({
          html: '사업자등록번호를 입력해주세요.',
          type: "info",
          confirmButtonText: "확인"
        });

        return;
      }
    }

    if (that.client.ownerName == null) {
      swal({
        html: '대표자명을 입력해주세요.',
        type: "info",
        confirmButtonText: "확인"
      });

      return;
    }

    if (that.client.contactNo == null) {
      swal({
        html: '대표번호를 입력해주세요.',
        type: "info",
        confirmButtonText: "확인"
      });

      return;
    } else {
      if (that.contactNo1 == '없음') {
        if (that.client.contactNo.length != 7 && that.client.contactNo.length != 8) {
          swal({
            html: '대표번호를 입력해주세요.',
            type: "info",
            confirmButtonText: "확인"
          });

          return;
        }
      } else {
        if (that.client.contactNo.length != 9 && that.client.contactNo.length != 10 && that.client.contactNo.length != 11) {
          swal({
            html: '대표번호를 입력해주세요.',
            type: "info",
            confirmButtonText: "확인"
          });

          return;
        }
      }

      if (that.contactNo2.length != 3 && that.contactNo2.length != 4) {
        swal({
          html: '대표번호를 입력해주세요.',
          type: "info",
          confirmButtonText: "확인"
        });

        return;
      }

      if (that.contactNo3.length != 4) {
        swal({
          html: '대표번호를 입력해주세요.',
          type: "info",
          confirmButtonText: "확인"
        });

        return;
      }
    }

    if (that.client.address == null || that.client.address.trim() == '') {
      swal({
        html: '주소를 입력해주세요.',
        type: "info",
        confirmButtonText: "확인"
      });

      return;
    }

    if (that.client.postalCode.trim() == '') {
      swal({
        html: '주소를 입력해주세요.',
        type: "info",
        confirmButtonText: "확인"
      });

      return;
    }

    if (that.client.postalCode.length == 0) {
      swal({
        html: '주소를 입력해주세요.',
        type: "info",
        confirmButtonText: "확인"
      });

      return;
    }

    if (that.client.postalCode.length != 5) {
      swal({
        html: '신주소를 입력해주세요.',
        type: "info",
        confirmButtonText: "확인"
      });

      return;
    }

    if (that.client.businessType == null) {
      swal({
        html: '업태를 입력해주세요.',
        type: "info",
        confirmButtonText: "확인"
      });

      return;
    }

    if (that.client.businessKind == null) {
      swal({
        html: '업종을 입력해주세요.',
        type: "info",
        confirmButtonText: "확인"
      });

      return;
    }

    if (that.client.taxInvoiceEmail == null) {
      swal({
        html: '세금계산서 이메일을 입력해주세요.',
        type: "info",
        confirmButtonText: "확인"
      });

      return;
    }

    if (that.client.usage == null) {
      swal({
        html: '가상계좌 사용목적을 입력해주세요.',
        type: "info",
        confirmButtonText: "확인"
      });

      return;
    }

    if (that.client.managerName == null) {
      swal({
        html: '담당자명을 입력해주세요.',
        type: "info",
        confirmButtonText: "확인"
      });

      return;
    }

    if (that.client.managerOfficeNo == null) {
      swal({
        html: '담당자 전화번호를 입력해주세요.',
        type: "info",
        confirmButtonText: "확인"
      });

      return;
    } else {
      if (that.managerOfficeNo1 == '없음') {
        if (that.client.managerOfficeNo.length != 7 && that.client.managerOfficeNo.length != 8) {
          swal({
            html: '담당자 전화번호를 입력해주세요.',
            type: "info",
            confirmButtonText: "확인"
          });

          return;
        }
      } else {
        if (that.client.managerOfficeNo.length != 9 && that.client.managerOfficeNo.length != 10 && that.client.managerOfficeNo.length != 11) {
          swal({
            html: '담당자 전화번호를 입력해주세요.',
            type: "info",
            confirmButtonText: "확인"
          });

          return;
        }
      }

      if (that.managerOfficeNo2.length != 3 && that.managerOfficeNo2.length != 4) {
        swal({
          html: '담당자 전화번호를 입력해주세요.',
          type: "info",
          confirmButtonText: "확인"
        });

        return;
      }

      if (that.managerOfficeNo3.length != 4) {
        swal({
          html: '담당자 전화번호를 입력해주세요.',
          type: "info",
          confirmButtonText: "확인"
        });

        return;
      }
    }

    if (that.client.managerContactNo == null) {
      swal({
        html: '담당자 휴대폰번호를 입력해주세요.',
        type: "info",
        confirmButtonText: "확인"
      });

      return;
    } else {
      if (that.client.managerContactNo.length != 10 && that.client.managerContactNo.length != 11) {
        swal({
          html: '담당자 휴대폰번호를 입력해주세요.',
          type: "info",
          confirmButtonText: "확인"
        });

        return;
      }

      if (that.managerContactNo2.length != 3 && that.managerContactNo2.length != 4) {
        swal({
          html: '담당자 휴대폰번호를 입력해주세요.',
          type: "info",
          confirmButtonText: "확인"
        });

        return;
      }

      if (that.managerContactNo3.length != 4) {
        swal({
          html: '담당자 휴대폰번호를 입력해주세요.',
          type: "info",
          confirmButtonText: "확인"
        });

        return;
      }
    }

    if (that.client.managerEmail == null) {
      swal({
        html: '담당자 이메일을 입력해주세요.',
        type: "info",
        confirmButtonText: "확인"
      });

      return;
    }

    if (that.client.accountList[0].accountNo == '') {
      swal({
        html: '정산계좌1을 입력해주세요.',
        type: "info",
        confirmButtonText: "확인"
      });

      return;
    }

    if (that.client.accountList[0].name == '') {
      swal({
        html: '정산계좌1의 명칭을 입력해주세요.',
        type: "info",
        confirmButtonText: "확인"
      });

      return;
    }

    if (that.client.accountList[1].accountNo != '') {
      if (that.client.accountList[1].name == '') {
        swal({
          html: '정산계좌2의 명칭을 입력해주세요.',
          type: "info",
          confirmButtonText: "확인"
        });

        return;
      }
    }

    if (that.client.accountList[1].name != '') {
      if (that.client.accountList[1].accountNo == '') {
        swal({
          html: '정산계좌2를 입력해주세요.',
          type: "info",
          confirmButtonText: "확인"
        });

        return;
      }
    }

    if (that.client.accountList[2].accountNo != '') {
      if (that.client.accountList[2].name == '') {
        swal({
          html: '정산계좌3의 명칭을 입력해주세요.',
          type: "info",
          confirmButtonText: "확인"
        });

        return;
      }
    }

    if (that.client.accountList[2].name != '') {
      if (that.client.accountList[2].accountNo == '') {
        swal({
          html: '정산계좌3를 입력해주세요.',
          type: "info",
          confirmButtonText: "확인"
        });

        return;
      }
    }

    if (that.plan != null) {
      if (typeof that.plan.id === 'undefined') {
        swal({
          html: '수수료를 선택해주세요.',
          type: "info",
          confirmButtonText: "확인"
        });

        return;
      }

      that.client.planId = that.plan.id;

      if (that.client.planId == 0) {
        swal({
          html: '수수료를 선택해주세요.',
          type: "info",
          confirmButtonText: "확인"
        });

        return;
      }
    } else {
      swal({
        html: '수수료를 선택해주세요.',
        type: "info",
        confirmButtonText: "확인"
      });

      return;
    }

    if (that.client.vaOwnerNameTypeCd == '1N0002') {
      if (that.client.vaOwnerNamePrefix == '') {
        swal({
          html: '수취인명을 입력해주세요.',
          type: "info",
          confirmButtonText: "확인"
        });

        return;
      }
    }

    if (!$('#agree').prop('checked')) {
      swal({
        html: '개인정보 제공 및 활용에 동의해주세요.',
        type: "info",
        confirmButtonText: "확인"
      });

      return;
    }

    swal({
      type: 'question',
      html: "서비스를 신청하시겠습니까?",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '확인',
      cancelButtonText: '취소',
      reverseButtons: true
    }).then(function (result) {
      if (result.value) {
        that.clientApplyService.clientApply(that.client).subscribe(
          (data) => {

          }, (err) => {
            const message = err['error']['message'];
            const html = message.replace('\n', '<p>');

            swal({
              html: html,
              type: 'info',
              confirmButtonText: '확인'
            });

            return;
          }, () => {
            that.applyModalClose();
            that.applySuccessModalShow();
          }
        )
      }
    });
  }

  applyClientReset() {
    this.client = new Client();

    this.client.vaBankId = '';
    this.client.agree = false;
    this.client.planId = 0;
    this.plan = null;

    this.identityNo1 = '';
    this.identityNo2 = '';
    this.identityNo3 = '';

    this.contactNo1 = '02';
    this.contactNo2 = '';
    this.contactNo3 = '';

    this.managerOfficeNo1 = '02';
    this.managerOfficeNo2 = '';
    this.managerOfficeNo3 = '';

    this.managerContactNo1 = '010';
    this.managerContactNo2 = '';
    this.managerContactNo3 = '';

    this.client.accountList[0].accountNo = '';
    this.client.accountList[0].name = '';
    this.client.accountList[1].accountNo = '';
    this.client.accountList[1].name = '';
    this.client.accountList[2].accountNo = '';
    this.client.accountList[2].name = '';
  }

  applyModalClose() {
    this.applyClientReset();

    $('#applyClient .btn-close').click();
  }

  /**
   * 신청하기 완료
   */
  applySuccessModalShow() {
    showModal('#applySuccessClient');
  }
}
