import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

declare var $: any;

@Component({
  moduleId: module.id,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  forwardURI: string;

  constructor(private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.forwardURI = this.route.snapshot.queryParams['forward'] || '';
    if (this.forwardURI != '') {
      this.router.navigate([this.forwardURI]);
    }
  }
}
