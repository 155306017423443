import {Component} from '@angular/core';

import {ParentMenu} from "../_models/common/menu.model";
import {CommonService} from "../_service/common/common.service";

declare var $: any;

@Component({
  moduleId: module.id,
  selector: 'beforeLogin',
  templateUrl: 'beforeLogin.component.html'
})

export class BeforeLoginComponent {
  id: string;
  name: string;
  url: string;
  status: string;

  defaultMenu: ParentMenu[];

  constructor(private commonService: CommonService) {
    this.getAuthenticMenu();
  }

  getAuthenticMenu() {
    this.commonService.getAuthenticMenu('').subscribe(
      (data) => {
        this.defaultMenu = data['children'];
      }
    )
  }

  onClose() {
    $('#sitemapModal .btn-close').click();
  }

  getAuthenticCode() {
    let status2 = sessionStorage.getItem("status");
    if (this.status != status2) {
      this.status = status2;

      if (status2 === '130002') {

      } else if(status2 == null) {

      } else {
        this.getAuthenticMenu();
      }
    }
    return true;
  }
}
