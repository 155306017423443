import {Code} from "../common/code.model";
import {Account} from "../common/account.model";

export class Consumer {
  selected: boolean;
  id: string;
  clientId: string;
  name: string;
  accountNo: string;
  property1: string;
  property2: string;
  property3: string;
  property4: string;
  cashReceiptIdNo: string;
  contactNo: string;
  email: string;
  status: Code[] = [];
  remark: string;
  accountList: Account[] = [];
  submitDate: Date;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class ConsumerIndex {
  id: string;
  name: string;
  identityNo: string;
  ownerName: string;
  contactNo: string;
  managerName: string;
  managerContactNo: string;
  managerEmail: string;
  usage: string;
  businessType: string;
  businessKind: string;
  postalCode: string;
  address: string;
  accountList: Account[] = [];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class ConsumerProperty {
  id: number;
  name: string;
  use: boolean;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
