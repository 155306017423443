import {Component} from '@angular/core';

declare var $:any;

@Component({
  moduleId: module.id,
  selector: 'termTab3',
  templateUrl: 'termTab3.component.html'
})

export class TermTab3Component {
  constructor() {
    window.scrollTo(0, 0);
  }
}
