import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import 'rxjs/add/operator/map';
import {ApiService} from './common/api.service';
import {Observable} from 'rxjs';
import {Code} from '../_models/common/code.model';

export class ClientMessage {
  id: number;
  requestDate: Date;
  type: Code;
  destination: string;
  consumerName: string;
  consumerId: string;
  content: string;
  status: Code;
  result: Code;
}

/**
 * 이용기관 이미지 서비스
 *
 * @author wisehouse@finger.co.kr
 */
@Injectable()
export class ClientMessageService {
  constructor(private http: HttpClient, private apiService: ApiService) {

  }

  public getClientMessageList(requestDtFrom: string, requestDtTo: string, destinationId: string, consumerName: string, type: string[], statusCd: string[], orderBy: string, pageNo: number, pageSize: number) {
    const param = {};
    if (requestDtFrom) {
      param['requestDtFrom'] = requestDtFrom;
    }
    if (requestDtTo) {
      param['requestDtTo'] = requestDtTo;
    }
    if (destinationId) {
      param['destination'] = destinationId;
    }
    if (consumerName) {
      param['consumerName'] = consumerName;
    }
    if (type) {
      param['typeCd'] = type;
    }
    if (statusCd) {
      param['statusCd'] = statusCd;
    }

    param['orderBy'] = orderBy;

    param['pageNo'] = pageNo;
    param['pageSize'] = pageSize;

    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/message', {
      params: param
    });
  }

  resendMessage(messageId: number) {
    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/message/' + messageId + '/resend', {});
  }
}

