import {Injectable} from '@angular/core';

@Injectable()
export class Globals {
  public connectedDevice: any;
  public getUrl: string;

  constructor() {
    // this.getUrl = window.location.hostname;
    //
    // switch (this.getUrl) {
    //   case 'www.billtag.co.kr' : this.connectedDevice = 'www.billtag.co.kr'; break;
    //   case 'test.billtag.co.kr' : this.connectedDevice = 'test.billtag.co.kr:18040'; break;
    //   case 'localhost' : this.connectedDevice = 'localhost:8081'; break;
    // }
  }
}
