import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from "./authentication.service";

@Injectable()
export class LogoutFilter implements CanActivate {

  constructor(private router: Router, private authenticationService: AuthenticationService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const status = sessionStorage.getItem('status');

    if (status === null) {
      return true;
    }

    return this.authenticationService.loginIndex().map(data => {
      const sessionClientId = sessionStorage.getItem('id');
      const clientId = data['id'];
      const role = data['role'];
      const clientStatus = data['status'] && data['status']['id'];

      if (role === null) {
        sessionStorage.clear();
        return true;
      }

      if (clientStatus !== '130003') {
        sessionStorage.clear();
        return true;
      }

      if (clientId !== sessionClientId) {
        sessionStorage.clear();
        return true;
      }

      this.router.navigate(['']);
      return true;
    });
  }
}
