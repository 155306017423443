import {Code} from "../common/code.model";

/**
 * 청구
 */
export class Notice {
  selected: boolean;

  id: string;
  clientId: string;
  consumerId: string;
  amount: number;
  noticeMonth: string;
  noticeDt: string;
  paymentDtFrom: string;
  paymentDtTo: string;
  name: string;
  accountNo: string;
  consumerAccountNo: string;
  consumerName: string;
  consumerProperty1: string;
  consumerProperty2: string;
  consumerProperty3: string;
  consumerProperty4: string;
  consumerCashReceiptIdNo: string;
  consumerContactNo: string;
  consumerEmail: string;
  itemList: NoticeItemList[];
  status: Code[] = [];
  remark: string;
  itemCount: number;
  noticeItemList: any[] = [];
  paymentDate: Date;
  paymentDt: string;
  receiptAmount: number;

  paymentDateFrom: string;
  paymentDateTo: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

/**
 * 청구항목 itemList
 */
export class NoticeItemList {
  exampleId: string = null;
  name: string;
  amount: number;
  remark: string;
  status: Code[] = [];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

/**
 * 청구항목
 */
export class NoticeTemplate {
  selected: boolean;

  id: string;
  name: string;
  orderNo: string;
  accountOrderNo: string;
  defaultAmount: string;
  optional: boolean;
  invoiceName: string;
  invoiceOrderNo: number;
  cashReceipt: boolean;
  cashReceiptClientIdNo: string;
  status: Code[] = [];
  createDate: Date = new Date();
  createUser: Date = new Date();
  modifyDate: Date = new Date();
  modifyUser: Date = new Date();

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

/**
 * 청구등록
 */
export class CreateNotice {
  consumerId: string;
  noticeMonth: string;
  paymentDtFrom: string;
  paymentDtTo: string;
  itemList: NoticeItemList[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

/**
 * 청구수정
 */
export class ModifyNotice {
  consumerId: string;
  noticeMonth: string;
  paymentDtFrom: string;
  paymentDtTo: string;
  itemList: NoticeItemList[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

/**
 * 대시보드 > 대시보드
 */
export class NoticeCount {
  monthlyNoticeCount: number;
  paidNoticeCount: number;
  payableNoticeCount: number;
  partialNoticeCount: number;
}
