import * as moment from 'moment';

export class YearMonth {
  constructor(private date: Date, private count: number = 0) {

  }

  public getYear() {
    return moment(this.date).format('YYYY');
  }

  public getMonth() {
    return moment(this.date).format('MM');
  }

  public getYearMonth() {
    return moment(this.date).format('YYYYMM');
  }

  public toYesrString() {
    return moment(this.date).format('YYYY');
  }

  public toString() {
    return moment(this.date).format('YYYY년 M월');
  }

  public getCount() {
    return this.count;
  }
}
