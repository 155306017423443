export class Modal {
  model: any;
  visible: boolean;
  properties;

  constructor() {
    this.visible = false;
    this.properties = {};
  }

  setProperty(name, value) {
    this.properties[name] = value;
  }

  getProperty(name) {
    let value = this.properties[name];
    if(value == null) {
      value = '';
    }
    return value;
  }

  isVisible() {
    return this.visible;
  }

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
    this.properties = {};
  }
}
