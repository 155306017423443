import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

import {YearMonth} from "../../_models/common/yearMonth.model";

import * as moment from 'moment';

declare var $: any;

/**
 * 월검색
 *
 * @author mljeong@finger.co.kr
 */
@Component({
  moduleId: module.id,
  selector: 'year-month',
  templateUrl: 'yearMonth.component.html'
})

export class YearMonthComponent implements OnInit, OnChanges {
  @Input('yearMonth')
  private yearMonth: string;

  @Output('yearMonthChange')
  private yearMonthChange: EventEmitter<string> = new EventEmitter();

  year: string;
  month: string;

  yearList: YearMonth[];

  constructor() {
    this.yearList = [];

    this.getYearList();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.yearMonth == null) {
      this.year = moment(new Date()).format('YYYY');
      this.month = moment(new Date()).format('MM');
    } else {
      this.year = moment(this.yearMonth, 'YYYYMM').format('YYYY');
      this.month = moment(this.yearMonth, 'YYYYMM').format('MM');
    }
  }

  getYearList() {
    const now = new Date();

    for (let i = 3; i >= -3; i--) {
      let each = new Date();
      each.setDate(1);
      each.setFullYear(moment(now).year() + i);
      this.yearList.push(new YearMonth(each));
    }
  }

  changeYear() {
    if (this.year == '선택') {
      this.yearMonth = '';
    } else {
      this.yearMonth = this.year + this.month;
    }

    this.emit();
  }

  changeMonth() {
    if (this.month == '선택') {
      this.yearMonth = '';
    } else {
      this.yearMonth = this.year + this.month;
    }

    this.emit();
  }

  emit() {
    this.yearMonthChange.emit(this.yearMonth);
  }
}
