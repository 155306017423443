import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ApiService} from '../common/api.service';

import 'rxjs/add/operator/map';

/**
 * 회원 가입 서비스
 */

@Injectable()
export class ApplyService {
  constructor(private http: HttpClient, private apiService: ApiService) {
  }

  /**
   * 휴대폰 번호조회
   */
  getStep1() {
    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/agree/step1');
  }

  /**
   * 휴대폰 번호 인증 요청
   */
  postStep1(contactNo: string) {
    const body = {
      contactNo: contactNo,
      agree: 'Y'
    };

    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/agree/step1', body);
  }

  /**
   * 휴대폰 번호 인증
   */
  postStep2(verificationId: number, passcode: string) {
    const body = {
      verifyId: verificationId,
      passCode: passcode
    };

    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/agree/step2', body);
  }

  /**
   * 계약사항 조회 ( 등록정보 확인 )
   */
  getStep3() {
    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + "/agree/step3");
  }

  /**
   * 계약사항 확인 등록
   */
  postStep3(confirm1: string, confirm2: string) {
    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + "/agree/step3", {
      confirm1: confirm1,
      confirm2: confirm2
    }).map((response: Response) => {
      return response;
    });
  }

  /**
   * 약관동의 등록
   */
  postStep4(agree1: string, agree2: string, agree3: string) {
    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + "/agree/step4", {
      agree1: agree1,
      agree2: agree2,
      agree3: agree3
    }).map((response: Response) => {
      return response;
    });
  }

  /**
   * 비밀번호 변경
   */
  postStep5(oldPassword: string, newPassword: string, newPasswordConfirm: string) {
    const body = {
      'oldPassword': oldPassword,
      'newPassword': newPassword,
      'confirm': newPasswordConfirm
    };

    return this.http.post(this.apiService.APIURL + this.apiService.APIVERSION + '/agree/step5', body).map((response: Response) => {
      return response;
    });
  }
}

