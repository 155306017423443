import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from './authentication.service';
import swal from 'sweetalert2';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authenticationService: AuthenticationService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authenticationService.loginIndex().map(data => {
      const user = data;

      const userId = user['id'];
      const role = user['role'];
      const sessionUserId = sessionStorage.getItem('sessionUserId');
      let status = '';

      if (user['status']) {
        status = user['status']['id'];
      } else {
        console.log('statusId is null');

        sessionStorage.clear();

        swal({
          text: '정상적으로 로그아웃 되셨습니다.',
          type: 'success',
          confirmButtonText: '확인'
        });

        this.router.navigate(['/']);
        return false;
      }

      if (userId === null) {
        console.log('userId is null');
        sessionStorage.clear();
        this.router.navigate(['/']);
        return false;
      }

      if (role === null) {
        console.log('role is null');
        sessionStorage.clear();
        this.router.navigate(['/']);
        return false;
      }

      if (status !== '130003') {
        console.log('status is not client_admin');
        sessionStorage.clear();
        this.router.navigate(['/']);
        return false;
      }

      if (userId !== sessionUserId) {
        console.log('userId does not equal sessionUserId');
        sessionStorage.clear();
        this.router.navigate(['/']);
        return false;
      }

      return true;
    });
  }
}
