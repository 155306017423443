import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ApiService} from '../common/api.service';

import 'rxjs/add/operator/map';

@Injectable()
export class InvoiceService {
  constructor(private http: HttpClient, private apiService: ApiService) {
  }

  /**
   * 대시보드 > 이용 수수료 현황
   */
  getInvoiceCurrent() {
    return this.http.get(this.apiService.APIURL + this.apiService.APIVERSION + '/service/invoice/current');
  }
}
