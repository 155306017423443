import {Component} from '@angular/core';

import {MeService} from '../_service/common/me.service';

declare var $: any;
declare var showModal: any;

@Component({
  moduleId: module.id,
  selector: 'top',
  templateUrl: 'top.component.html'
})

export class TopComponent {
  name: string;

  constructor(private meService: MeService) {
    this.getMeIndex();
  }

  getMeIndex() {
    this.meService
      .getIndex()
      .subscribe(
        (data) => {
          if (data) {
            this.name = data['name'];
          }
        }
      );
  }

  loginYn() {
    const status = sessionStorage.getItem('status');
    const result = status == '130003';
    return result;
  }
}
