import {NgModule} from '@angular/core';

import {FormatNonghyupAccountNoPipe} from './format-nonghyup-account-no.pipe';
import {FormatContactNoPipe} from './format-contact-no.pipe';
import {FormatBusinessRegisterNoPipe} from './format-business-register-no.pipe';
import {FormatCashReceiptNoPipe} from "./format-cash-receipt-no.pipe";

@NgModule({
  imports: [
    // dep modules
  ],
  declarations: [
    FormatNonghyupAccountNoPipe
    , FormatContactNoPipe
    , FormatBusinessRegisterNoPipe
    , FormatCashReceiptNoPipe
  ],
  exports: [
    FormatNonghyupAccountNoPipe
    , FormatContactNoPipe
    , FormatBusinessRegisterNoPipe
    , FormatCashReceiptNoPipe
  ]
})
export class PipeModule {
}
