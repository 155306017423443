import {Pipe, PipeTransform} from '@angular/core';

/**
 * 사업자 등록번호
 *
 * @author wisehouse@finger.co.kr
 */
@Pipe({name: 'formatBusinessRegisterNo'})
export class FormatBusinessRegisterNoPipe implements PipeTransform {

  transform(input: string): string {
    if (input == null) {
      return input;
    }

    let input2 = input.replace('-', '');

    let length = input2.length;

    if (length != 10) {
      return input2;
    }

    let output = [];
    output.push(input2.substring(0, 3));
    output.push(input2.substring(3, 5));
    output.push(input2.substring(5));

    return output.join('-');
  }
}
