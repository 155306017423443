import {RouterModule, Routes} from '@angular/router';

import {AuthGuard} from './_service/authentic/auth.guard';
import {LogoutFilter} from './_service/authentic/logout.filter';

import {LoginComponent} from './contents/login/login.component';
import {DashboardComponent} from "./contents/dashboard/dashboard.component";
import {Error404Component} from "./error/error404.component";

const appRoutes: Routes = [
  // 404 에러시 출력 페이지
  {path: '',              component: LoginComponent, canActivate: [LogoutFilter]},
  {path: '', redirectTo: '', pathMatch: 'full'},
  {path: 'dashboard' ,    component: DashboardComponent, canActivate: [AuthGuard]},
  {path: 'consumer' ,     loadChildren: './contents/consumer/consumer.module#ConsumerModule', canActivate: [AuthGuard]},
  {path: 'notice'   ,     loadChildren: './contents/notice/notice.module#NoticeModule', canActivate: [AuthGuard]},
  {path: 'receipt'  ,     loadChildren: './contents/receipt/receipt.module#ReceiptModule', canActivate: [AuthGuard]},
  {path: 'join'     ,     loadChildren: './contents/join/join.module#JoinModule'},
  {path: 'misc'     ,     loadChildren: './contents/misc/misc.module#MiscModule'},
  {path: 'login-as-client/:id', loadChildren: './loginAsClient/loginAsClient.module#LoginAsClientModule'},
  {path: '**', component: Error404Component}
];

export const routing = RouterModule.forRoot(appRoutes);
